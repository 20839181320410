import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [{
      path: "/login",
      name: "login",
      component: () => import( '../views/Login.vue'),
      meta: {
        isPublic: true
      }
    },
    {
      path: "/",
      name: "main",
      component: () => import( /* webpackChunkName: "main" */ '../views/Main.vue'),
      children: [
        {
          path: "/contact-us/list",
          component: () => import( /* webpackChunkName: "link_list" */ '../views/ContactUsList.vue'),
        },
        {
          path: "/lead/list",
          component: () => import( '../views/LeadList.vue'),
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.isPublic && !localStorage.token) {
    return next('/login')
  }
  next()
})
export default router;